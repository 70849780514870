import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {
  ApplicationConfig,
  provideZoneChangeDetection,
  inject,
  provideAppInitializer
} from '@angular/core'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {
  EZC_THEME_PROVIDERS,
  LoginService
} from '@ez-court/fe-theme'
import {environment} from '../environments/environment'
import {routes} from './app.routes'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'

export const appConfig: ApplicationConfig = {
  providers: [
    ...EZC_THEME_PROVIDERS,
    provideAppInitializer(() => {
      return inject(LoginService).bootstrap({
        domain: environment.domain,
        authApiUrl: environment.authServiceUrl,
        usersApiUrl: environment.userApiUrl,
        registerUrl: environment.userRegisterUrl
      })
    }),
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        responseInterceptor
      ])
    )
  ]
}