import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {LoginService} from '@ez-court/fe-theme'
import {Observable} from 'rxjs'

/**
 * Intercept all requests and add auth token. We should however
 * do this only on white listed requests so that we do not
 * send our tokens when not needed. In its simplest form this
 * assumes that all outgoing requests after the token is set
 * should have it.
 */
export const authInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const loginService = inject(LoginService)

  if (!loginService.accessToken$()) {
    return next(req)
  }

  if (req.url.includes('/api')) {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${loginService.accessToken$()}`)
    })
    return next(authReq)
  }

  return next(req)
}
