import {Component, inject} from '@angular/core'
import {MatMenu, MatMenuItem} from '@angular/material/menu'
import {RouterOutlet} from '@angular/router'
import {
  FooterComponent,
  HeaderComponent,
  LoginService
} from '@ez-court/fe-theme'
import {environment} from '../environments/environment'

@Component({
    selector: 'ezc-root',
    templateUrl: './app.component.html',
    imports: [
        RouterOutlet,
        HeaderComponent,
        FooterComponent,
        MatMenu,
        MatMenuItem
    ],
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public logoUrl = environment.logoUrl
  protected loginService: LoginService = inject(LoginService)
}